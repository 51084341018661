var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _c(
      "div",
      { staticClass: "login-bg text-center" },
      [
        _c("Message"),
        _c("img", {
          staticClass: "login-bg-img",
          attrs: { src: require("@/assets/bg-login.png"), alt: "" },
        }),
        _c(
          "div",
          {
            staticClass: "login-component-wrap",
            style: { "--view-height": _vm.viewHeight },
            attrs: { id: "component" },
          },
          [
            _c("div", { staticClass: "login", attrs: { component: "login" } }, [
              _c("img", {
                staticClass: "logo-icon",
                attrs: { src: require("@/assets/bulle.png"), alt: "" },
              }),
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/zhikelogin.png"), alt: "" },
              }),
              _c(
                "div",
                { staticClass: "defaultImg" },
                [
                  _vm.noticeList.length < 1
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/defaultImg.png"),
                          alt: "",
                        },
                      })
                    : _vm.noticeList.length === 1
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%", height: "100%" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.noticeList[0].url,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: {
                                  src: _vm.noticeList[0].photos[0],
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "info" }, [
                                _vm._v(_vm._s(_vm.noticeList[0].title)),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "el-carousel",
                        { attrs: { arrow: "never" } },
                        _vm._l(_vm.noticeList, function (item) {
                          return _c("el-carousel-item", { key: item.id }, [
                            _c(
                              "a",
                              { attrs: { href: item.url, target: "_blank" } },
                              [
                                _c("img", {
                                  staticStyle: {
                                    height: "100%",
                                    width: "100%",
                                  },
                                  attrs: { src: item.photos[0], alt: "" },
                                }),
                                _c("div", { staticClass: "info" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                              ]
                            ),
                          ])
                        }),
                        1
                      ),
                ],
                1
              ),
              _c("div", { staticClass: "verification" }, [
                _c("img", {
                  staticClass: "zhikeyunying",
                  attrs: { src: require("@/assets/zhikeyunying.png"), alt: "" },
                }),
                _c("div", { staticClass: "form-group sms-code" }, [
                  _c("div", { staticClass: "account-icon" }, [
                    !_vm.phone
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/phone.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/activePhone.png"),
                            alt: "",
                          },
                        }),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.phone,
                        expression: "phone",
                      },
                    ],
                    staticClass: "form-control phone",
                    style: _vm.phone
                      ? "background-color: #fff;"
                      : "background-color: #f0f0f0",
                    attrs: {
                      type: "text",
                      placeholder: "请输入手机号",
                      maxlength: "11",
                      required: "",
                    },
                    domProps: { value: _vm.phone },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.doLogin.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.phone = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      class: [
                        "btn",
                        "btn-primary",
                        { disabled: _vm.smsCodeBtnText.includes("剩") },
                      ],
                      staticStyle: {
                        width: "120px",
                        height: "40px",
                        right: "5px",
                        padding: "0",
                        "font-size": "20px",
                      },
                      on: { click: _vm.getSmsCode },
                    },
                    [_vm._v(" " + _vm._s(_vm.smsCodeBtnText) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "form-group sms-code" }, [
                  _c("div", { staticClass: "account-icon" }, [
                    !_vm.smsCode
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/security.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/activeSecurity.png"),
                            alt: "",
                          },
                        }),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.smsCode,
                        expression: "smsCode",
                      },
                    ],
                    staticClass: "form-control bg-gray",
                    style: _vm.smsCode
                      ? "background-color: #fff;"
                      : "background-color: #f0f0f0",
                    attrs: {
                      type: "text",
                      placeholder: "请输入验证码",
                      required: "",
                    },
                    domProps: { value: _vm.smsCode },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.doLogin.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.smsCode = $event.target.value
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { "margin-top": "50px" } },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-block btn-login",
                          staticStyle: { width: "100%" },
                          attrs: { type: "submit" },
                          on: {
                            click: _vm.doLogin,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.doLogin.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" 登录 ")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }