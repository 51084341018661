<template>
    <div style="overflow: auto">
        <div class="login-bg text-center">
            <Message></Message>
            <img src="@/assets/bg-login.png" class="login-bg-img" alt=""/>

            <div
                    id="component"
                    class="login-component-wrap"
                    :style="{ '--view-height': viewHeight }"
            >
                <div component="login" class="login">
                    <img src="@/assets/bulle.png" class="logo-icon" alt=""/>
                    <img class="logo" src="@/assets/zhikelogin.png" alt=""/>
                    <div class="defaultImg">
                        <img
                                v-if="noticeList.length < 1"
                                src="@/assets/defaultImg.png"
                                alt=""
                        />
                        <div
                                v-else-if="noticeList.length === 1"
                                style="width: 100%; height: 100%"
                        >
                            <a :href="noticeList[0].url" target="_blank">
                                <img
                                        :src="noticeList[0].photos[0]"
                                        style="width: 100%; height: 100%"
                                        alt=""
                                />
                                <div class="info">{{ noticeList[0].title }}</div>
                            </a>
                        </div>
                        <el-carousel v-else arrow="never">
                            <el-carousel-item v-for="item in noticeList" :key="item.id">
                                <a :href="item.url" target="_blank">
                                    <img
                                            :src="item.photos[0]"
                                            alt=""
                                            style="height: 100%; width: 100%"
                                    />
                                    <div class="info">{{ item.title }}</div>
                                </a>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="verification">
                        <img class="zhikeyunying" src="@/assets/zhikeyunying.png" alt=""/>
                        <div class="form-group sms-code">
                            <div class="account-icon">
                                <img v-if="!phone" src="@/assets/phone.png" alt=""/>
                                <img v-else src="@/assets/activePhone.png" alt=""/>
                            </div>
                            <input
                                    type="text"
                                    v-model="phone"
                                    class="form-control phone"
                                    placeholder="请输入手机号"
                                    maxlength="11"
                                    required=""
                                    @keyup.enter="doLogin"
                                    :style="
                  phone
                    ? 'background-color: #fff;'
                    : 'background-color: #f0f0f0'
                "
                            />
                            <button
                                    :class="[
                  'btn',
                  'btn-primary',
                  { disabled: smsCodeBtnText.includes('剩') },
                ]"
                                    @click="getSmsCode"
                                    style="
                  width: 120px;
                  height: 40px;
                  right: 5px;
                  padding: 0;
                  font-size: 20px;
                "
                            >
                                {{ smsCodeBtnText }}
                            </button>
                        </div>
                        <div class="form-group sms-code">
                            <div class="account-icon">
                                <img v-if="!smsCode" src="@/assets/security.png" alt=""/>
                                <img v-else src="@/assets/activeSecurity.png" alt=""/>
                            </div>
                            <input
                                    type="text"
                                    v-model="smsCode"
                                    class="form-control bg-gray"
                                    placeholder="请输入验证码"
                                    required=""
                                    @keyup.enter="doLogin"
                                    :style="
                  smsCode
                    ? 'background-color: #fff;'
                    : 'background-color: #f0f0f0'
                "
                            />
                        </div>
                        <div class="row" style="margin-top: 50px">
                            <div class="col-12">
                                <button
                                        type="submit"
                                        style="width: 100%"
                                        @click="doLogin"
                                        @keyup.enter="doLogin"
                                        class="btn btn-block btn-login"
                                >
                                    登录
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Message from "@/components/Message.vue";
    import {sendSmsUrl, smsSignInUrl, queryPCLoginUrl} from "@/requestUrl";
    import {STORAGE_KEY} from "@/constant";
    import * as icon from "../assets/shool.svg";


    export default {
        props: {},
        components: {
            Message,
        },
        data() {
            return {
                icon,
                phone: "",
                smsCode: "",
                viewHeight: document.body.clientHeight,
                smsCodeBtnText: "获取验证码",
                noticeList: "",
            };
        },

        async mounted() {
            window.addEventListener("resize", () => {
                this.viewHeight = document.body.clientHeight;
            });

            this.getNotice();
            this.phone = localStorage.getItem(STORAGE_KEY.ACCOUNT) || "";
            this.$vc.on(this.$route.path, "login", "doLogin", () => {
                this.doLogin();
            });
        },
        methods: {

            // 发送验证码后定时操作
            sendSmsTimer() {
                let surplusTime = 60;
                const timer = setInterval(() => {
                    surplusTime--;
                    this.smsCodeBtnText = `还剩${surplusTime}秒`;
                    if (surplusTime === 0) {
                        clearInterval(timer);
                        this.smsCodeBtnText = "获取验证码";
                    }
                }, 1000);
            },
            // 获取验证码
            getSmsCode() {
                if (this.smsCodeBtnText.includes("剩")) {
                    // this.$vc.message("验证码已发送，请稍后再试");
                    return;
                }
                this.$fly
                    .post(
                        sendSmsUrl,
                        {
                            phone: this.phone,
                        },
                        {
                            headers: {
                                isForm: true,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.code !== 0 && !res.data) {
                            return;
                        }
                        sessionStorage.setItem(STORAGE_KEY.X_TOKEN, res.data.token);
                        this.sendSmsTimer();
                        localStorage.removeItem(STORAGE_KEY.X_TOKEN);
                    });
            },
            // 登录接口
            doLogin: function () {
                if (!this.$vc.notNull(this.phone)) {
                    this.$vc.toast("请输入手机号");
                    return;
                }
                if(this.phone.length < 11){
                    this.$vc.toast("手机号格式错误");
                    return;
                }
                if (!this.$vc.notNull(this.smsCode)) {
                    this.$vc.toast("请输入验证码");
                    return;
                }

                this.$fly
                    .post(smsSignInUrl, {
                        phone: this.phone,
                        smsCode: this.smsCode,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            this.$vc.message(res.msg);
                            return;
                        }
                        const {userInfo} = res.data;
                        if (userInfo.resignTime && userInfo.resignBy) {
                            this.$vc.message("您已离职");
                            return;
                        }
                        const beforeLoginPage = sessionStorage.getItem(
                            STORAGE_KEY.BEFORE_LOGIN_PAGE//之前的页面
                        );
                        this.$vc.clearCacheData();//删除缓存数据
                        localStorage.setItem(STORAGE_KEY.ACCOUNT, this.phone);
                        localStorage.setItem(STORAGE_KEY.X_TOKEN, res.data.token);
                        localStorage.setItem(STORAGE_KEY.USER_INFO, JSON.stringify(userInfo));
                        //  判断是不是有之前的页面
                        if (beforeLoginPage) {
                            this.$router.push({...JSON.parse(beforeLoginPage)});
                            return;
                        }
                        this.$router.push({name: "index"});
                    });
            },
            //获取公告
            getNotice() {
                this.$fly.get(queryPCLoginUrl).then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    res.data.forEach((item) => {
                        item.photos = JSON.parse(item.photos);
                    });
                    this.noticeList = res.data;
                });
            },
        },
    };
</script>
<style lang="stylus" scoped>
    .login-bg {
        height: 100vh;
        min-width: 1046px;
        min-height: 540px;
    }

    .yanjiuyuan {
        position: absolute;
        left: 40px;
        bottom: 40px;
    }

    .login-component-wrap {
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);

        .login {
            position: relative;
        }

        .defaultImg {
            width: 460px;
            height: 300px;
            position: absolute;
            top: 206.5px;
            left: 184px;
        }

        .verification {
            width: 400px;
            position: absolute;
            top: 200px;
            right: 160px;

            .zhikeyunying {
                margin-bottom: 50px;
            }

            .form-group {
                input {
                    width: 400px;
                    height: 50px;
                    border-radius: 6px;
                    font-size: 24px;
                    padding-left: 60px;
                    // border 1px solid #f0f0f0
                }

                .account-icon {
                    width: 20px;
                    position: absolute;
                    left: 20px;
                    top: 10px;
                }

                .phone {
                    &:focus {
                        background-color: #fff !important;
                    }
                }

                .bg-gray {
                    &:focus {
                        background-color: #fff !important;
                    }
                }
            }
        }
    }

    .logo {
        position: absolute;
        left: 160px;
        top: 50px;
        width: 440px;
    }

    .slogan-icon {
        width: 140%;
        margin-top: 60px;
        margin-left: -20%;
    }

    .sms-code {
        position: relative;

        .btn {
            position: absolute;
            top: 50%;
            right: 20px;
            width: 160px;
            height: 45px;
            line-height: 33px;
            font-size: 23px;
            background: #D8FFF8;
            color: #1ab394;
            transform: translateY(-50%);
            border-color: rgba(0, 0, 0, 0);
            border-radius: 6px;

            &.disabled {
                cursor: not-allowed;
                // background #f0f0f0
                // color #999
            }
        }
    }

    .btn-login {
        background-color: #293846;
        height: 60px;
        font-size: 30px;
        color: #fff;
        border-radius: 6px;
    }

    /deep/ .el-carousel__button {
        width: 30px;
        height: 6px;
        // border-radius 3px
    }

    /deep/ .is-active {
        button {
            background-color: #00B694;
        }
    }

    /deep/ .el-carousel__indicator--horizontal {
        padding: 5px 4px;
    }

    .info {
        position: relative;
        z-index: 100;
        width: 100%;
        height: 60px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        text-align: left;
        padding: 21px;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: underline;
        }

        &::before {
            position: absolute;
            left: 7px;
            content: '';
            width: 6px;
            height: 18px;
            background-color: #fff;
        }
    }

    img {
        image-rendering: pixelated;
    }

    @media screen and (max-height: 1070px) {
        .login-component-wrap {
            -webkit-font-smoothing: subpixel-antialiased;
            transform: translate(-50%, -50%) scale(calc((var(--view-height) / 1080))) translateZ(0);
        }

        .yanjiuyuan {
            transform: scale(calc((var(--view-height) / 1080))) translateZ(0);
        }
    }

    .bottom-logo {
        //width: 430px;
        box-sizing: border-box;
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 40px;
        left: 40px;
    }

    .bottom-logo img:nth-child(1) {
        margin-right: 10px;
    }

    .bottom-logo img:nth-child(2) {
        margin-right: 10px;
    }
</style>
